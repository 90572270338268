.idea-hiddenfunc {
    cursor: pointer;
}

.random-next {
    max-width: 500px;
    margin: auto;
    margin-top: 20px;
    width: calc(100% - 40px);
    margin-bottom: 25px;

    .random-next-btn {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .mute-btn {
        margin-top: 10px;
        min-width: 75px;
    }
}

.ideas-header {
    position: relative;
    margin-bottom: 0px;

    h1 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        img {
            width: 45px;
            margin-left: 5px;
            padding-top: 3px;
        }
    }

    .back-btn {
        margin: auto;
        margin-top: 15px;
        display: block;
    }
}

.ideas {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    .idea {
        width: 100%;
        margin: 0 0 5px;
        padding: 0 10px;

        @include respond-to(screen-sm) {
            width: 500px;
        }

        h3 {
            font-family: $font-stack-common;
        }

        .idea-name {
            max-width: 500px;
            font-family: $font-stack-common;
            position: relative;

            &:after {
                content: "\00a0 Click to reveal";
                width:  100%;
                height: calc(100% - 7px);
                position: absolute;
                left: 0;
                top: 0;
                padding: 0;
                padding-top: 7px;
                font-size: 1rem;
                line-height: 1rem;
                color: #fff;
                background-color: black;
                transition: $animationSpeed;
            }

            &:not(.veiled) {
                pointer-events: none;
                &:after {
                    opacity: 0;
                }
            }

            &.veiled {
                &:after {
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }

        .idea-para {
            max-width: 500px;
            margin-bottom: 5px;

            &.share-title {
                font-size: 1.4rem;
            }
        }

        .idea-image-wrapper {
            position: relative;
            margin-top: 5px;

            .idea-image-inner {
                .idea-image {
                    position: relative;
                    width: 100%;
                    margin-top: 10px;
                    cursor: pointer;

                    @include respond-to(screen-sm) {
                        width: auto;
                    }
                }
            }
        }

        .idea-tags {
            margin-top: 5px;

            button {
                margin-right: 5px;
            }
        }

        .share-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 10px;

            &.sharing-mode {
                flex-direction: row;
            }

            .share-btn {
                width: fit-content;
                padding: 5px 8px 5px;

                &:hover {
                    color: white;
                }
            }

            .back-btn {
                margin-left: auto;
            }
        }

        .loading-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;

            .loading-image {
                width: 50px;
            }

            h4 {
                margin-top: 5px;
                font-family: $font-stack-common;
            }
        }

        .idea-credit,
        .image-credit {
            div {
                line-height: 1.6rem;
            }
        }

        .idea-credit {
            display: flex;
            flex-direction: row;

            div {
                font-size: 13px;
            }
        }

    }
}
