html {
}

.App {
    display: flex;
    align-items: center;
    flex-direction: column;

    .App-inner {
        width: 100vw;

        .container {
            max-width: 1000px;
            margin: auto;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-family: $font-stack-header;
    }

    p  {
        padding: 0;
        margin: 0;
        font-family: $font-stack-common;
    }

    a {
        text-decoration: none;
    }

    button {
        position: relative;
        cursor: pointer;
        padding: 3px 8px;
        color: black;
        background-color: #D93B3B;
        border: 0;
        box-sizing: border-box;
        transform: translateY(0px);
        font-family: $font-stack-websafe;
        transition: $animationSpeed;

        &:after {
            content: "";
            height: 1px;
            position: absolute;
            background-color: rgba(0,0,0,0.6);
            transform-origin: top center;
            transform: scaleY(3) translateY(0);
            transition: $animationSpeed;

            width: calc(100%);
            bottom: -1px;
            left: 0px;
        }

        &:not(.active) {
            &:after {}
        }

        &.active,
        &.non-toggle:active {
            color: white;
            background-color: #D71C2F;
            transform: translateY(2px);

            &:after {
                transform: scaleY(0) translateY(0px);
            }
        }

        &:hover {
            color: white;
        }

        &:not(.active) {
            &:hover {
                color: black;
            }
        }

        &.flat-btn {
            border: 0;
            padding: 3px 8px 5px;
            transition: $animationSpeed;

            &:hover {
                color: white;
            }

            &:after {
                display: none;
            }
        }

    }

    @keyframes tooltipFadeInOut {
      0%   {opacity: 0;}
      25%  {opacity: 1;}
      50%  {opacity: 1;}
      100% {opacity: 0;}
    }

    .tooltip-wrapper {
        display: flex;

        .tooltip-text {
            top: 0;
            margin-left: 0px;
            background-color: gray;
            color: #fff;
            width: fit-content;
            padding: 3px 8px 5px;
            position: relative;
            opacity: 0;
            transition: $animationSpeed;

            &.active {
                animation-name: tooltipFadeInOut;
                animation-duration: 2s;
                top: 2px;
            }
        }

        .arrow-left {
          position: absolute;
          top: 4px;
          left: -7px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right:10px solid gray;
        }
    }

    hr {
        margin-top: 10px;
        border: 0;
        border-bottom: 2px solid rgba(0,0,0,0.2);
    }

    & .hidden {
        display: none;
    }

    .close-cross {
        width: 25px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        &:after,
        &:before {
            content: "";
            position: absolute;
            width: 15px;
            height: 3px;
            background-color: white;
            top: 12px;
            right: 4px;
            transition: $animationSpeed;
        }

        &:after {
            transform: rotate(45deg)
        }
        &:before {
            transform: rotate(-45deg)
        }
    }

    .lightbox {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: rgba(0,0,0,0.85);
        overflow: hidden;
        transition: $animationSpeed;

        &.invisible {
            opacity: 0;
            pointer-events: none;
        }

        .close-enlarged-image {
            cursor: pointer;

            &:after,
            &:before {
                width: 45px;
                height: 8px;
                top: 30px;
                right: 10px;
            }
        }

        img {
            width: auto;
            max-width: 90%;
            cursor: pointer;
        }
    }

    .loading-latest-copy {
        // position: absolute;
        // top: 35px;
        // left: 50%;
        // transform: translateX(-50%);

        h3 {
            span {}

            img {
                width: 20px;
            }
        }
    }
}

.home {}