.pagination {
    margin-bottom: 40px;

    .pagination-inner {
        h1 {
            text-align: center;
        }
    }

    .paginationer {
        ul {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding-left: 0;

            li {
                list-style: none;
                margin: 0 5px;

                &.previous-btn {
                    margin-right: 10px;
                }

                &.next-btn {
                    margin-left: 10px;
                }

                button {
                    padding: 4px 10px;
                    transition: $animationSpeed;
                }

                &.active {
                    button {
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
