.user-panel-btn {
    height: 26px;
    display: flex;
    align-items: center;
    margin-top: 14px;

    @include respond-to(screen-sm) {
        margin-top: 0;
    }

    &.active {
        svg * {
            fill: #fff;
            color: #fff;
        }
    }

    img,
    svg {
        height: 100%;
    }
}

.user-panel-container {
    .user-panel-body {
        width: 520px;
        margin: auto;
    }
}

.user-panel-buttons {
    margin-top: 20px;

    button {
        margin-right: 10px;
    }
}

.signup-link {
    margin-top: 10px;
}

.loggingin-loader {
    position: relative;
    width: 100%;
    height: 30px;

    .loading-latest-copy {
        top: 0;
        left: 0;
        transform: translateX(0);
    }
}

.coming-soon {
    * {
        font-family: $font-stack-websafe;
    }

    h1 {
        text-align: center;
    }
}

