.whatisthisshit {
    height: 26px;
    display: flex;
    align-items: center;
    margin-top: 14px;

    @include respond-to(screen-sm) {
        margin-top: 0;
    }

    &:after {
        content: '';
        display: none;
    }

    img {
        height: 80%;
    }
}

.whatisthisshit-container {
    .whatisthisshit-body {
        width: calc(100% - 150px);
        max-width: 974px;
        padding: 0 50px 50px;
        background-color: white;

        h1 {
            margin-top: 50px;
            text-align: center;
        }

        p {
            text-align: center;
            margin-top: 10px;
        }
    }
}