.App header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 0;
    background-color: $BrandColour;

    @include respond-to(screen-sm) {
        height: 60px;
    }

    a.website-name-link {
        color: black;

        .header-website-name {
            padding: 0 5px;
            cursor: pointer;
        }
    }

    h1 {
        margin-bottom: 5px;
        font-size: 1.7rem;
        text-align: center;

        @include respond-to(screen-md) {
            font-size: 2rem;
        }
    }
}

.site-panel {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to(screen-sm) {
        flex-direction: row;
    }

    .header-panel-buttons {
        margin-bottom: 10px;

        @include respond-to(screen-sm) {
            margin-bottom: 0;
        }
    }

    .header-seperator {
        display: none;
        align-items: center;

        @include respond-to(screen-sm) {
            display: flex;
        }
    }

    .random {
        margin-bottom: 15px;

        @include respond-to(screen-sm) {
            margin-bottom: 0;
        }
    }
}

.tag-search {
    position: relative;

    input {
        padding: 4px 4px;
        border: 0;

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px;
        }
    }

    .close-cross {
        &:after,
        &:before {
            background-color: black;
        }
    }
}

.whatisthisshit {
    height: 26px;
    display: flex;
    align-items: center;
    margin-top: 14px;

    @include respond-to(screen-sm) {
        margin-top: 0;
    }

    &:after {
        content: '';
        display: none;
    }

    img {
        height: 80%;
    }
}