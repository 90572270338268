@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@400;700&display=swap');

$break-small: 500px !default;
$break-medium: 769px !default;
$break-large: 1041px !default;
$break-extralarge: 1441px !default;
$product-page-width: 1440px !default;

$font-stack-header: 'Josefin Slab', serif;
$font-stack-common: 'Josefin Sans', sans-serif;
$font-stack-websafe: 'trebuchet ms',sans-serif;

$BrandColour: Tomato;
$animationSpeed: 0.3s;

@mixin respond-to($media) {
    @if $media == screen-sm {
        @media only screen and (min-width: $break-small) { @content; }
    }
    @else if $media == screen-md {
        @media only screen and (min-width: $break-medium) { @content; }
    }
    @else if $media == screen-lg {
        @media only screen and (min-width: $break-large) { @content; }
    }
    @else if $media == screen-xl {
        @media only screen and (min-width: $break-extralarge) { @content; }
    }
    @else if $media == hidpi {
        @media
            only screen and (-webkit-min-device-pixel-ratio: 1.3),
            only screen and (-o-min-device-pixel-ratio: 13/10),
            only screen and (min-resolution: 120dpi) { @content; }
    }
}

@import './partials/_common';
@import './partials/_header';
@import './partials/_whatisthisshit';
@import './partials/_user-panel';
@import './partials/_pagination';
@import './partials/_ideas';
@import './partials/_page-idea-not-found';
@import './partials/_footer';
@import './partials/_form';