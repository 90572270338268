@charset "UTF-8";
@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@400;700&display=swap");
.App {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.App .App-inner {
  width: 100vw;
}
.App .App-inner .container {
  max-width: 1000px;
  margin: auto;
}
.App h1, .App h2, .App h3, .App h4, .App h5, .App h6 {
  margin: 0;
  font-family: "Josefin Slab", serif;
}
.App p {
  padding: 0;
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
}
.App a {
  text-decoration: none;
}
.App button {
  position: relative;
  cursor: pointer;
  padding: 3px 8px;
  color: black;
  background-color: #D93B3B;
  border: 0;
  box-sizing: border-box;
  transform: translateY(0px);
  font-family: "trebuchet ms", sans-serif;
  transition: 0.3s;
}
.App button:after {
  content: "";
  height: 1px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  transform-origin: top center;
  transform: scaleY(3) translateY(0);
  transition: 0.3s;
  width: calc(100%);
  bottom: -1px;
  left: 0px;
}
.App button.active, .App button.non-toggle:active {
  color: white;
  background-color: #D71C2F;
  transform: translateY(2px);
}
.App button.active:after, .App button.non-toggle:active:after {
  transform: scaleY(0) translateY(0px);
}
.App button:hover {
  color: white;
}
.App button:not(.active):hover {
  color: black;
}
.App button.flat-btn {
  border: 0;
  padding: 3px 8px 5px;
  transition: 0.3s;
}
.App button.flat-btn:hover {
  color: white;
}
.App button.flat-btn:after {
  display: none;
}
@keyframes tooltipFadeInOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.App .tooltip-wrapper {
  display: flex;
}
.App .tooltip-wrapper .tooltip-text {
  top: 0;
  margin-left: 0px;
  background-color: gray;
  color: #fff;
  width: fit-content;
  padding: 3px 8px 5px;
  position: relative;
  opacity: 0;
  transition: 0.3s;
}
.App .tooltip-wrapper .tooltip-text.active {
  animation-name: tooltipFadeInOut;
  animation-duration: 2s;
  top: 2px;
}
.App .tooltip-wrapper .arrow-left {
  position: absolute;
  top: 4px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid gray;
}
.App hr {
  margin-top: 10px;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
.App .hidden {
  display: none;
}
.App .close-cross {
  width: 25px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.App .close-cross:after, .App .close-cross:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 3px;
  background-color: white;
  top: 12px;
  right: 4px;
  transition: 0.3s;
}
.App .close-cross:after {
  transform: rotate(45deg);
}
.App .close-cross:before {
  transform: rotate(-45deg);
}
.App .lightbox {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.85);
  overflow: hidden;
  transition: 0.3s;
}
.App .lightbox.invisible {
  opacity: 0;
  pointer-events: none;
}
.App .lightbox .close-enlarged-image {
  cursor: pointer;
}
.App .lightbox .close-enlarged-image:after, .App .lightbox .close-enlarged-image:before {
  width: 45px;
  height: 8px;
  top: 30px;
  right: 10px;
}
.App .lightbox img {
  width: auto;
  max-width: 90%;
  cursor: pointer;
}
.App .loading-latest-copy h3 img {
  width: 20px;
}

.App header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 0;
  background-color: Tomato;
}
@media only screen and (min-width: 500px) {
  .App header {
    height: 60px;
  }
}
.App header a.website-name-link {
  color: black;
}
.App header a.website-name-link .header-website-name {
  padding: 0 5px;
  cursor: pointer;
}
.App header h1 {
  margin-bottom: 5px;
  font-size: 1.7rem;
  text-align: center;
}
@media only screen and (min-width: 769px) {
  .App header h1 {
    font-size: 2rem;
  }
}

.site-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 500px) {
  .site-panel {
    flex-direction: row;
  }
}
.site-panel .header-panel-buttons {
  margin-bottom: 10px;
}
@media only screen and (min-width: 500px) {
  .site-panel .header-panel-buttons {
    margin-bottom: 0;
  }
}
.site-panel .header-seperator {
  display: none;
  align-items: center;
}
@media only screen and (min-width: 500px) {
  .site-panel .header-seperator {
    display: flex;
  }
}
.site-panel .random {
  margin-bottom: 15px;
}
@media only screen and (min-width: 500px) {
  .site-panel .random {
    margin-bottom: 0;
  }
}

.tag-search {
  position: relative;
}
.tag-search input {
  padding: 4px 4px;
  border: 0;
}
.tag-search input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.tag-search .close-cross:after, .tag-search .close-cross:before {
  background-color: black;
}

.whatisthisshit {
  height: 26px;
  display: flex;
  align-items: center;
  margin-top: 14px;
}
@media only screen and (min-width: 500px) {
  .whatisthisshit {
    margin-top: 0;
  }
}
.whatisthisshit:after {
  content: "";
  display: none;
}
.whatisthisshit img {
  height: 80%;
}

.whatisthisshit {
  height: 26px;
  display: flex;
  align-items: center;
  margin-top: 14px;
}
@media only screen and (min-width: 500px) {
  .whatisthisshit {
    margin-top: 0;
  }
}
.whatisthisshit:after {
  content: "";
  display: none;
}
.whatisthisshit img {
  height: 80%;
}

.whatisthisshit-container .whatisthisshit-body {
  width: calc(100% - 150px);
  max-width: 974px;
  padding: 0 50px 50px;
  background-color: white;
}
.whatisthisshit-container .whatisthisshit-body h1 {
  margin-top: 50px;
  text-align: center;
}
.whatisthisshit-container .whatisthisshit-body p {
  text-align: center;
  margin-top: 10px;
}

.user-panel-btn {
  height: 26px;
  display: flex;
  align-items: center;
  margin-top: 14px;
}
@media only screen and (min-width: 500px) {
  .user-panel-btn {
    margin-top: 0;
  }
}
.user-panel-btn.active svg * {
  fill: #fff;
  color: #fff;
}
.user-panel-btn img,
.user-panel-btn svg {
  height: 100%;
}

.user-panel-container .user-panel-body {
  width: 520px;
  margin: auto;
}

.user-panel-buttons {
  margin-top: 20px;
}
.user-panel-buttons button {
  margin-right: 10px;
}

.signup-link {
  margin-top: 10px;
}

.loggingin-loader {
  position: relative;
  width: 100%;
  height: 30px;
}
.loggingin-loader .loading-latest-copy {
  top: 0;
  left: 0;
  transform: translateX(0);
}

.coming-soon * {
  font-family: "trebuchet ms", sans-serif;
}
.coming-soon h1 {
  text-align: center;
}

.pagination {
  margin-bottom: 40px;
}
.pagination .pagination-inner h1 {
  text-align: center;
}
.pagination .paginationer ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 0;
}
.pagination .paginationer ul li {
  list-style: none;
  margin: 0 5px;
}
.pagination .paginationer ul li.previous-btn {
  margin-right: 10px;
}
.pagination .paginationer ul li.next-btn {
  margin-left: 10px;
}
.pagination .paginationer ul li button {
  padding: 4px 10px;
  transition: 0.3s;
}
.pagination .paginationer ul li.active button {
  cursor: default;
  pointer-events: none;
}

.idea-hiddenfunc {
  cursor: pointer;
}

.random-next {
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
  width: calc(100% - 40px);
  margin-bottom: 25px;
}
.random-next .random-next-btn {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.random-next .mute-btn {
  margin-top: 10px;
  min-width: 75px;
}

.ideas-header {
  position: relative;
  margin-bottom: 0px;
}
.ideas-header h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ideas-header h1 img {
  width: 45px;
  margin-left: 5px;
  padding-top: 3px;
}
.ideas-header .back-btn {
  margin: auto;
  margin-top: 15px;
  display: block;
}

.ideas {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}
.ideas .idea {
  width: 100%;
  margin: 0 0 5px;
  padding: 0 10px;
}
@media only screen and (min-width: 500px) {
  .ideas .idea {
    width: 500px;
  }
}
.ideas .idea h3 {
  font-family: "Josefin Sans", sans-serif;
}
.ideas .idea .idea-name {
  max-width: 500px;
  font-family: "Josefin Sans", sans-serif;
  position: relative;
}
.ideas .idea .idea-name:after {
  content: " Click to reveal";
  width: 100%;
  height: calc(100% - 7px);
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  padding-top: 7px;
  font-size: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: black;
  transition: 0.3s;
}
.ideas .idea .idea-name:not(.veiled) {
  pointer-events: none;
}
.ideas .idea .idea-name:not(.veiled):after {
  opacity: 0;
}
.ideas .idea .idea-name.veiled:after {
  opacity: 1;
  cursor: pointer;
}
.ideas .idea .idea-para {
  max-width: 500px;
  margin-bottom: 5px;
}
.ideas .idea .idea-para.share-title {
  font-size: 1.4rem;
}
.ideas .idea .idea-image-wrapper {
  position: relative;
  margin-top: 5px;
}
.ideas .idea .idea-image-wrapper .idea-image-inner .idea-image {
  position: relative;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}
@media only screen and (min-width: 500px) {
  .ideas .idea .idea-image-wrapper .idea-image-inner .idea-image {
    width: auto;
  }
}
.ideas .idea .idea-tags {
  margin-top: 5px;
}
.ideas .idea .idea-tags button {
  margin-right: 5px;
}
.ideas .idea .share-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.ideas .idea .share-wrapper.sharing-mode {
  flex-direction: row;
}
.ideas .idea .share-wrapper .share-btn {
  width: fit-content;
  padding: 5px 8px 5px;
}
.ideas .idea .share-wrapper .share-btn:hover {
  color: white;
}
.ideas .idea .share-wrapper .back-btn {
  margin-left: auto;
}
.ideas .idea .loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.ideas .idea .loading-wrapper .loading-image {
  width: 50px;
}
.ideas .idea .loading-wrapper h4 {
  margin-top: 5px;
  font-family: "Josefin Sans", sans-serif;
}
.ideas .idea .idea-credit div,
.ideas .idea .image-credit div {
  line-height: 1.6rem;
}
.ideas .idea .idea-credit {
  display: flex;
  flex-direction: row;
}
.ideas .idea .idea-credit div {
  font-size: 13px;
}

.page-idea-not-found {
  padding-top: 70px;
}

.footer {
  width: 100%;
  height: 100px;
  background: white;
}
.form-wrapper form {
  width: 500px;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin-top: 10px;
}
.form-wrapper form label {
  font-family: "trebuchet ms", sans-serif;
}
.form-wrapper form input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
}
.form-wrapper form .input-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
}

