.form-wrapper {
    form {
        width: 500px;
        box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.25);
        padding: 10px;
        margin-top: 10px;

        label {
            font-family: $font-stack-websafe;
        }

        input {
            width: 100%;
            box-sizing: border-box;
            padding: 5px;
        }

        .input-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            margin-bottom: 10px;
        }
    }
}